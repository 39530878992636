import {mapGetters, mapMutations} from "vuex"

export default {
  data() {
    return {
      total_pcs: 0
    }
  },
  methods: {
    ...mapGetters({
      getOrderData: 'getOrderData',
      getSavedItems: 'getSavedItems',
      getNonDraftOrderForms: 'getNonDraftOrderForms'
    }),
    ...mapMutations({
      resetStore: 'resetStore',
      setReseting: 'setReseting'
    }),
    getItemLineTotalPrice(product) {
      return this.getVolumeDiscount(product).total
    },
    goToCart() {
      //TODO save current cup state before going to shopping cart
      let uuid = this.$route.params.uuid
      if (uuid == undefined) {
        uuid = this.getOrderData().id
      }
      this.$router.push({
        name: 'Cart',
        params: {
            uuid: uuid
        }
      })
    },
    resetCart() {
      const answer = window.confirm('Haluatko jatkaa edellisen tilauksen muokkausta vai aloittaa alusta? Mikäli olet ottanut edellisen tilauksen linkin talteen, pääset muokkaamaan sitä linkin kautta.')
      if (!answer) return false

      if (this.$route.name == 'Landing') {
        this.resetStore()
        this.$router.go() // reload the page
      } else {
        this.setReseting(true)
        this.$router.push({name: 'Landing'})
      }
    }
  },
  computed: {
    products() {
      if (this.getOrderData() != null && this.getOrderData().items != undefined && this.getOrderData().items) return this.getOrderData().items
      else return []
    },
    has_cups() {
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].product.resource_type == 'Cup') {
          return true
        }
      }
      return false
    },
    not_draft_products() {
      if (this.getOrderData() != null) return this.getSavedItems()
      else return []
    },
    non_draft_forms() {
      if (this.getOrderData() != null) return this.getNonDraftOrderForms()
      else return []
    },
    selectedProduct() {
      const id = this.$route.params.item
      return this.products.find(x => x.id === id)
    },
    getVolumeDiscount() {
      return item => {
        let productPrices = this.$store.getters.getVolumesDiscount(item.product.id)
        let cupDiscount = productPrices.find(p => p.volume_point == item.amount)
        if (cupDiscount == undefined) {
          return 100
        }
        return cupDiscount
      }
    },
    getVolumeDiscountPerCupSize() {
      return productId => {
        if(this.$store.getters.getFixedVoucher.product != undefined && this.$store.getters.getFixedVoucher.product === productId) {
          return this.$store.getters.getFixedVoucher.price
        }
        let productPrices = this.$store.getters.getVolumesDiscount(productId)
        let totalAmountProduct = this.$store.getters.getItemsTotalAmountByProduct(productId)
        let cupDiscount;
        if (productPrices.length == 1) {
          if (this.$store.getters.getProductById(productId)[0].resource_type == 'Lid' && this.has_cups) {
            return productPrices[0].price - 0.015
          }
          return productPrices[0].price
        }
        for (let i = 0; i < productPrices.length; i++) {
          if(productPrices.length == i - 1) {
            cupDiscount = productPrices[i]
          } else if(productPrices[i].volume_point <= totalAmountProduct && productPrices[i+1].volume_point > totalAmountProduct) {
            cupDiscount = productPrices[i]
            break
          }
        }
        return cupDiscount.price
      }
    },   
    getDesignServiceTotalFee() {
      return (this.non_draft_forms.length) * this.$store.getters.getDesignServiceFee.price
    },
    getTotalPrice() {
      let total = 0
      this.$store.getters.getProducts.forEach(product => {
        let totalAmountPerProduct = this.$store.getters.getItemsTotalAmountByProduct(product.id)
        if (totalAmountPerProduct > 0) {
          let priceEach = this.getVolumeDiscountPerCupSize(product.id)
          total += totalAmountPerProduct * priceEach
        }
      });
      return total.toFixed(2)
    }
  }
}
