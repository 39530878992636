<template>
  <div class="editor-container">
    <asModalDialog
      heading-text="3d esikatselu"
      :show-ok="false"
      :show-cancel="false"
      width="80%"
      min-width="500px"
      ref="previewModal"
      @modal-result-cancel="showGridIfRequired()"
      class="is-marginless"
    >
      <preview3d :textureWidth=1000 :textureHeight=400 :gblVariable="'Exterior'" :texture="texture" :uri="textureUri"/>
    </asModalDialog>
    <asModalDialog
        heading-text="Valitse fontti"
        :show-ok="false"
        :show-cancel="false"
        ref="fontModal"
        class="is-marginless"
        width="300px"
        min-width="300px"
      >
      <ul class="fontSelector">
        <li v-for="font in fonts" :key="font">
          <a :style="{'font-family': font}" :class="{'selected': selectedFont==font}" @click="changeFont(font)">{{ font }}</a>
        </li>
      </ul>
      </asModalDialog>
      <asModalDialog
        heading-text="Valitse taustakuva"
        :show-ok="false"
        :show-cancel="false"
        ref="imageModal"
        class="is-marginless"
        width="500px"
        min-width="500px"
      >
        <input id="backgroundImage"
         ref="backgroundImage"
         style="display: none"
         type="file" accept="image/*"
         @change="croppieBg"/>
        <p class="editor-text-content mt-3 mb-5">
          {{ this.$t("message.components.fabricComponent.lataaOma") }}
        </p>
        <a
            class="is-flex is-flex-direction-column is-align-items-start editor-text-icon-modal mb-5"
            @click="addBackground()"
          >
                    <span class="icon is-small mr-0"
                    ><i class="fas fa-solid fa-file-arrow-up" aria-hidden="true"></i
                    ></span>
          <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.valitseTiedosto") }}</span>
        </a>
        <div class="container imageSelector">
          <div class="columns is-multiline is-hidden-touch">
            <div class="column is-6" v-for="image in getImagesBank()" :key="image">
              <img width="200" crossorigin="anonymous" :src="image.image" @click="selectImage(image)">
            </div>
          </div>
          <div class="columns is-hidden-desktop">
            <div class="column has-text-centered" v-for="image in getImagesBank()" :key="image">
              <img width="200" crossorigin="anonymous" :src="image.image" @click="selectImage(image)">
            </div>
          </div>
        </div>
      </asModalDialog>
    <div class="columns is-fullwidth top-editor-controls is-hidden-touch" id=topEditorControls>
      <div class="column is-1 is-offset-1">
        <a
            class="is-flex is-flex-direction-column is-align-items-center"
            @click="undo"
        >
                    <span class="icon is-small mr-0"
                    ><i class="fas fa-undo" aria-hidden="true"></i
                    ></span>
          <span class="top-editor-text has-text-centered">{{ this.$t("message.components.fabricComponent.undoText") }}</span>
        </a>
      </div>
      <div class="column is-1">
        <a
            class="is-flex is-flex-direction-column is-align-items-center "
            @click="redo"
        >
                    <span class="icon is-small mr-0"
                    ><i class="fas fa-redo" aria-hidden="true"></i
                    ></span>
          <span class="top-editor-text has-text-centered">{{ this.$t("message.components.fabricComponent.redoText") }}</span>
        </a>
      </div>
      <div class="column is-1">
        <a
            class="is-flex is-flex-direction-column is-align-items-center "
            @click="initialCanvas"
        >
                    <span class="icon is-small mr-0"
                    ><i class="fas fa-trash" aria-hidden="true"></i
                    ></span>
          <span class="top-editor-text has-text-centered">{{ this.$t("message.components.fabricComponent.clearAllText") }}</span>
        </a>
      </div>
      <div class="column is-1">
        <a
            class="is-flex is-flex-direction-column is-align-items-center "
            @click="flipGrid"
        >
                    <span class="icon is-small mr-0"
                    ><i class="fas fa-border-style" aria-hidden="true"></i
                    ></span>
          <span class="top-editor-text has-text-centered">{{ this.$t("message.components.fabricComponent.grid") }}</span>
        </a>
      </div>
      <div class="column is-1">
        <a
            class="is-flex is-flex-direction-column is-align-items-center "
            @click="open3DPreview()"
        >
                    <span class="icon is-small mr-0"
                    ><i class="fas fa-regular fa-eye d-color" aria-hidden="true"></i
                    ></span>
          <span class="top-editor-text has-text-centered">{{ this.$t("message.components.fabricComponent.d3Esikatse") }}</span>
        </a>
      </div>
      <div class="column is-1 is-offset-4">
        <a
            class="is-flex is-flex-direction-column is-align-items-center "
            @click="openHelpModal()"
        >
                    <span class="icon is-small mr-0"
                    ><i class="fa-solid fa-circle-question" aria-hidden="true"></i
                    ></span>
        </a>
      </div>      
    </div>
    <div class="columns is-fullwidth top-editor-controls is-hidden-desktop is-mobile is-multiline" id=topEditorControls>
      <div class="column is-6">
        <a
            class="is-flex is-flex-direction-column is-align-items-center"
            @click="undo"
        >
                    <span class="icon is-small mr-0"
                    ><i class="fas fa-undo" aria-hidden="true"></i
                    ></span>
          <span class="top-editor-text has-text-centered">{{ this.$t("message.components.fabricComponent.undoText") }}</span>
        </a>
      </div>
      <div class="column is-6">
        <a
            class="is-flex is-flex-direction-column is-align-items-center "
            @click="redo"
        >
                    <span class="icon is-small mr-0"
                    ><i class="fas fa-redo" aria-hidden="true"></i
                    ></span>
          <span class="top-editor-text has-text-centered">{{ this.$t("message.components.fabricComponent.redoText") }}</span>
        </a>
      </div>
      <div class="column is-6">
        <a
            class="is-flex is-flex-direction-column is-align-items-center "
            @click="initialCanvas"
        >
                    <span class="icon is-small mr-0"
                    ><i class="fas fa-trash" aria-hidden="true"></i
                    ></span>
          <span class="top-editor-text has-text-centered">{{ this.$t("message.components.fabricComponent.clearAllText") }}</span>
        </a>
      </div>
      <div class="column is-6">
        <a
            class="is-flex is-flex-direction-column is-align-items-center "
            @click="open3DPreview()"
        >
                    <span class="icon is-small mr-0"
                    ><i class="fas fa-regular fa-eye d-color" aria-hidden="true"></i
                    ></span>
          <span class="top-editor-text has-text-centered">{{ this.$t("message.components.fabricComponent.d3Esikatse") }}</span>
        </a>
      </div>
      <div class="column is-6 is-offset-6">
        <a
            class="is-flex is-flex-direction-column is-align-items-center "
            @click="openHelpModal()"
        >
                    <span class="icon is-small mr-0"
                    ><i class="fa-solid fa-circle-question" aria-hidden="true"></i
                    ></span>
        </a>
      </div>      
    </div>
    <div class="columns is-mobile" ref="parentEditorWrapper" id="parentEditorWrapper" :style="editorHeightProperty">
      <div class="column is-10 is-offset-1 editor-wrapper pb-0" ref="editorwrapper" :style="editorWrapperStyle">
        <canvas id="editorcanvas" ref="can"></canvas>
      </div>
    </div>

    <!-- Start editor controls icons -->
    <div class="columns is-mobile">
      <div class="column is-10 is-offset-1 is-paddingless">
        <div class="tabs is-boxed">
          <ul>
            <li
                class="tab is-flex-grow-1 is-active" id="kuvaContent"
                @click="openTab('kuvaContent', 'kuva-content')"
            >
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-regular fa-file-image" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.backgroundText") }}</span>
              </a>
            </li>
            <li
                class="tab is-flex-grow-1" id="tekstiContent"
                @click="openTab('tekstiContent', 'teksti-content')"
            >
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-font" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.textText") }}</span>
              </a>
            </li>
            <li
                class="tab is-flex-grow-1" id="logoContent"
                @click="openTab('logoContent', 'logo-content')"
            >
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-solid fa-star" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.logoText") }}</span>
              </a>
            </li>
            <li
                class="tab is-flex-grow-1" id="shapesContent"
                @click="openTab('shapesContent', 'shapes-content')"
            >
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-regular fa-circle" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.shapesText") }}</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="columns is-mobile">
      <div class="column is-10 is-offset-1">
        <!-- Start editor Controls Content -->
        <div class="editor-action-content py-5 border-bottom">
          <!-- Text content -->
          <div
              id="teksti-content"
              class="content-tab is-align-items-center"
              style="display: none"
          >
            <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center">
              <a
                  class="is-flex is-flex-direction-column editor-text-icon is-align-items-center"
                  @click="addTextBox()"
              >
                <span class="icon is-small mr-0"
                  ><i class="fas fa-solid fa-plus" aria-hidden="true"></i
                ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.addNewText") }}</span>
              </a>
            </div>

            <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center">
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                  @click="openFontModal"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-font" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.fontText") }}</span>
              </a>
            </div>

            <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center">
              <div class="bg-color-picker-wrapper" v-if=showFontColorPicker>
                <ColorPicker
                     :color="fontColor"
                     @color-change="updateFontColor"
                     :visible-formats="['hsl']"
                     alpha-channel="hide"
                   >
                </ColorPicker>
                <div class="has-text-centered">
                    <label for="fontCyan">C:</label>
                    <input type="range" v-model="fontColorCMYK.cyan" id="fontCyan" min="0" max="100" step="1">
                    <span>{{ fontColorCMYK.cyan }}</span>
                </div>
                <div class="has-text-centered">
                    <label for="fontMagenta">M:</label>
                    <input type="range" v-model="fontColorCMYK.magenta" id="fontMagenta" min="0" max="100" step="1">
                    <span>{{ fontColorCMYK.magenta }}</span>
                </div>
                <div class="has-text-centered">
                    <label for="fontYellow">Y:</label>
                    <input type="range" v-model="fontColorCMYK.yellow" id="fontYellow" min="0" max="100" step="1">
                    <span>{{ fontColorCMYK.yellow }}</span>
                </div>
                <div class="has-text-centered">
                    <label for="fontBlack">K:</label>
                    <input type="range" v-model="fontColorCMYK.black" id="fontBlack" min="0" max="100" step="1">
                    <span>{{ fontColorCMYK.black }}</span>
                </div>
                 <ColorPickerFooter
                  :bgColor="fontColor || '#44BDC7'"
                  @cp-confirmButton="changeFontColor()"
                  @cp-cancelButton="showFontColorPicker=false"
                 />
               </div>
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                  @click="showFontColorPicker = !showFontColorPicker"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-palette" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.colorText") }}</span>
              </a>
            </div>
            <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center">
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                  @click="deleteItem()"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-trash" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.removeText") }}</span>
              </a>
            </div>
          </div>

          <!-- Logo content -->
          <div
              id="logo-content"
              class="content-tab"
              style="display: none"
          >
            <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center">
              <input ref="imageUpload" id="filereader" style="display: none" type="file" accept="image/*"
                @change="croppieLogo"/>
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                  @click="addLogo()"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-solid fa-file-arrow-up" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.selectImageText") }}</span>
              </a>
            </div>
            <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center">
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                  @click="deleteItem()"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-trash" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.removeText") }}</span>
              </a>
            </div>
          </div>

          <!-- Shapes content -->
          <div
              id="shapes-content"
              class="content-tab"
              style="display: none"
          >

            <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center">
              <div class="bg-color-picker-wrapper" v-if=showShapesColorPicker>
                <ColorPicker
                     :color="fontColor"
                     @color-change="updateShapeColor"
                     :visible-formats="['hsl']"
                     alpha-channel="hide"
                   >
                </ColorPicker>
                <div class="has-text-centered">
                    <label for="shapeCyan">C:</label>
                    <input type="range" v-model="shapeColorCMYK.cyan" id="shapeCyan" min="0" max="100" step="1">
                    <span>{{ shapeColorCMYK.cyan }}</span>
                </div>
                <div class="has-text-centered">
                    <label for="shapeMagenta">M:</label>
                    <input type="range" v-model="shapeColorCMYK.magenta" id="shapeMagenta" min="0" max="100" step="1">
                    <span>{{ shapeColorCMYK.magenta }}</span>
                </div>
                <div class="has-text-centered">
                    <label for="shapeYellow">Y:</label>
                    <input type="range" v-model="shapeColorCMYK.yellow" id="shapeYellow" min="0" max="100" step="1">
                    <span>{{ shapeColorCMYK.yellow }}</span>
                </div>
                <div class="has-text-centered">
                    <label for="shapeBlack">K:</label>
                    <input type="range" v-model="shapeColorCMYK.black" id="shapeBlack" min="0" max="100" step="1">
                    <span>{{ shapeColorCMYK.black }}</span>
                </div>
                 <ColorPickerFooter
                  :bgColor="shapeColor || '#44BDC7'"
                  @cp-confirmButton="changeShapesColor()"
                  @cp-cancelButton="showShapesColorPicker=false"
                 />
               </div>
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                  @click="showShapesColorPicker = !showShapesColorPicker"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-palette" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.colorText") }}</span>
              </a>
            </div>

            <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center">
              <a
                  class="is-flex is-flex-direction-column editor-text-icon is-align-items-center"
                  @click="addCircle()"
              >
                <span class="icon is-small mr-0"
                  ><i class="fas fa-regular fa-circle" aria-hidden="true"></i
                ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.addCircle") }}</span>
              </a>
            </div>

            <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center">
              <a
                  class="is-flex is-flex-direction-column editor-text-icon is-align-items-center"
                  @click="addRectangle()"
              >
                <span class="icon is-small mr-0"
                  ><i class="fas fa-regular fa-square" aria-hidden="true"></i
                ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.addRectangle") }}</span>
              </a>
            </div>

          </div>

          <!-- Kuva content -->
          <div
              id="kuva-content"
              class="content-tab default-active"
          >
            <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center is-hidden-touch">
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                  @click="openBankImageModal()"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-solid fa-file-arrow-up" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.vaihdaText") }}</span>
              </a>
            </div>
            <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center is-hidden-touch">
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                  @click="showBackground=true"
                  :class="{'icon-disabled': !hasBackgroundPicture}"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-rotate" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text"
                      :class="{'text-disabled': !hasBackgroundPicture}"
                >{{ this.$t("message.components.fabricComponent.kierraText") }}</span>
              </a>
            </div>
            <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center is-hidden-touch">
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                  @click="showBackground=true"
                  :class="{'icon-disabled': !hasBackgroundPicture}"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-arrows-alt" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text"
                      :class="{'text-disabled': !hasBackgroundPicture}"
                >{{ this.$t("message.components.fabricComponent.kohdistaText") }}</span>
              </a>
            </div>
            <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center is-hidden-touch">
              <div class="bg-color-picker-wrapper" v-if=showBgColorPicker>
                <ColorPicker
                     :color="backgroundColor"
                     @color-change="updateBgColor"
                     :visible-formats="['hsl']"
                     alpha-channel="hide"
                   >
                </ColorPicker>
                <div class="has-text-centered">
                    <label for="bgColorDesktopcyan">C:</label>
                    <input type="range" v-model="backgroundColorCMYK.cyan" id="bgColorDesktopcyan" min="0" max="100" step="1">
                    <span>{{ backgroundColorCMYK.cyan }}</span>
                </div>
                <div class="has-text-centered">
                    <label for="bgColorDesktopmagenta">M:</label>
                    <input type="range" v-model="backgroundColorCMYK.magenta" id="bgColorDesktopmagenta" min="0" max="100" step="1">
                    <span>{{ backgroundColorCMYK.magenta }}</span>
                </div>
                <div class="has-text-centered">
                    <label for="bgColorDesktopyellow">Y:</label>
                    <input type="range" v-model="backgroundColorCMYK.yellow" id="bgColorDesktopyellow" min="0" max="100" step="1">
                    <span>{{ backgroundColorCMYK.yellow }}</span>
                </div>
                <div class="has-text-centered">
                    <label for="bgColorDesktopblack">K:</label>
                    <input type="range" v-model="backgroundColorCMYK.black" id="bgColorDesktopblack" min="0" max="100" step="1">
                    <span>{{ backgroundColorCMYK.black }}</span>
                </div>
                <ColorPickerFooter
                  :bgColor="backgroundColor || '#44BDC7'"
                  @cp-confirmButton="changeBackgroundColor()"
                  @cp-cancelButton="showBgColorPicker=false"
                />
              </div>
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                  :class="{'icon-disabled': hasBackgroundPicture}"
                  @click="bgColorIconClick"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-palette" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text"
                      :class="{'text-disabled': hasBackgroundPicture}"
                >{{ this.$t("message.components.fabricComponent.colorText") }}</span>
              </a>
            </div>
            <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center is-hidden-touch">
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                  @click="deleteBackgroundPicture()"
                  :class="{'icon-disabled': !hasBackgroundPicture}"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-trash" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text"
                      :class="{'text-disabled': !hasBackgroundPicture}"
                >{{ this.$t("message.components.fabricComponent.deleteBackgroundPicture") }}</span>
              </a>
            </div>
            <div class="is-hidden-desktop container">
              <div class="columns is-mobile is-multiline">
                <div class="column is-6">
                  <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center">
                     <div class="bg-color-picker-wrapper" v-if=showBgColorPicker>
                       <ColorPicker
                           :color="backgroundColor"
                           @color-change="updateBgColor"
                           :visible-formats="['hsl']"
                           alpha-channel="hide"
                         >
                      </ColorPicker>
                      <div class="has-text-centered">
                          <label for="bgColorMobileCyan">C:</label>
                          <input type="range" v-model="backgroundColorCMYK.cyan" id="bgColorMobileCyan" min="0" max="100" step="1">
                          <span>{{ backgroundColorCMYK.cyan }}</span>
                      </div>
                      <div class="has-text-centered">
                          <label for="bgColorMobileMagenta">M:</label>
                          <input type="range" v-model="backgroundColorCMYK.magenta" id="bgColorMobileMagenta" min="0" max="100" step="1">
                          <span>{{ backgroundColorCMYK.magenta }}</span>
                      </div>
                      <div class="has-text-centered">
                          <label for="bgColorMobileYellow">Y:</label>
                          <input type="range" v-model="backgroundColorCMYK.yellow" id="bgColorMobileYellow" min="0" max="100" step="1">
                          <span>{{ backgroundColorCMYK.yellow }}</span>
                      </div>
                      <div class="has-text-centered">
                          <label for="bgColorMobileBlack">K:</label>
                          <input type="range" v-model="backgroundColorCMYK.black" id="bgColorMobileBlack" min="0" max="100" step="1">
                          <span>{{ backgroundColorCMYK.black }}</span>
                      </div>
                        <ColorPickerFooter
                          :bgColor="backgroundColor || '#44BDC7'"
                          @cp-confirmButton="changeBackgroundColor()"
                          @cp-cancelButton="showBgColorPicker=false"
                        />
                     </div>
                    <a
                        class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                        @click="openBankImageModal()"
                    >
                                <span class="icon is-small mr-0"
                                ><i class="fas fa-solid fa-file-arrow-up" aria-hidden="true"></i
                                ></span>
                      <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.vaihdaText") }}</span>
                    </a>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center">
                    <a
                        class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                        @click="showBackground=true"
                        :class="{'icon-disabled': !hasBackgroundPicture}"
                    >
                                <span class="icon is-small mr-0"
                                ><i class="fas fa-rotate" aria-hidden="true"></i
                                ></span>
                      <span class="editor-action-text"
                            :class="{'text-disabled': !hasBackgroundPicture}"
                      >{{ this.$t("message.components.fabricComponent.kierraText") }}</span>
                    </a>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center">
                    <a
                        class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                        @click="showBackground=true"
                        :class="{'icon-disabled': !hasBackgroundPicture}"
                    >
                                <span class="icon is-small mr-0"
                                ><i class="fas fa-arrows-alt" aria-hidden="true"></i
                                ></span>
                      <span class="editor-action-text"
                            :class="{'text-disabled': !hasBackgroundPicture}"
                      >{{ this.$t("message.components.fabricComponent.kohdistaText") }}</span>
                    </a>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center">
                  <a
                      class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                      :class="{'icon-disabled': hasBackgroundPicture}"
                      @click="bgColorIconClick"
                  >
                              <span class="icon is-small mr-0"
                              ><i class="fas fa-palette" aria-hidden="true"></i
                              ></span>
                    <span class="editor-action-text"
                          :class="{'text-disabled': hasBackgroundPicture}"
                    >{{ this.$t("message.components.fabricComponent.colorText") }}</span>
                  </a>
                 </div>
                </div>
                <div class="column is-6">
                  <div class="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-center">
                    <a
                        class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                        @click="deleteBackgroundPicture()"
                        :class="{'icon-disabled': !hasBackgroundPicture}"
                    >
                                <span class="icon is-small mr-0"
                                ><i class="fas fa-trash" aria-hidden="true"></i
                                ></span>
                      <span class="editor-action-text"
                            :class="{'text-disabled': !hasBackgroundPicture}"
                      >{{ this.$t("message.components.fabricComponent.deleteBackgroundPicture") }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- End editor control content -->
    <div style="display: none">
      <img :src="croppedLogo">
    </div>
    <div>
      <pop-modal :value="showLogo" @close="cancelCropModal">
        <vue-croppie
          class="croppie"
          ref="croppieRefLogo"
          :enableOrientation="true"
          :enableExif="true"
          :showZoomer="true"
          :enableResize="true"
          :maxZoom="2.4"
          @update="changeCropperLogo"
          :boundary="{ width: 450, height: 300}"
          :viewport="{ width: 200, height:100, 'type':'square' }"
        >
        </vue-croppie>

        <template v-slot:footer>
          <a
            class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
            @click="rotateLogo"
          >
                      <span class="icon is-small mr-0"
                      ><i class="fas fa-rotate" aria-hidden="true"></i
                      ></span>
            <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.kierraText") }}</span>
          </a>
          <div class="column is-flex is-justify-content-end">
            <button class="pm-small-button" @click="saveCroppedImageLogo">{{ this.$t("message.components.fabricComponent.saveText") }}</button>&nbsp;
            <button class="pm-small-button" @click="cancelCropModal">{{ this.$t("message.components.fabricComponent.cancelText") }}</button>
          </div>
        </template>
      </pop-modal>
    </div>
    <div>
      <pop-modal :value="true" :vanish="!showBackground" @close="cancelCropModalBg" v-if="canvasHeight">
        <div class="container">
          <div class="columns mb-0 pb-0">
            <div class="column is-12 mb-0 pb-0">
              <vue-croppie
                    class="croppie"
                    ref="croppieRefBg"
                    :enableOrientation="true"
                    :enableExif="true"
                    :showZoomer="true"
                    :enableResize="false"
                    :maxZoom="2.4"
                    :minZoom="0.1"
                    @update="changeCropperBg"
                    :boundary="bgCroppieBoundary"
                    :viewport="bgCroppieViewport"
                >
              </vue-croppie>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12 is-flex is-justify-content-end has-text-centered">
              <a
                  class="is-flex is-flex-direction-column is-align-items-center editor-text-icon"
                  style="width: 100%;"
                  @click="rotateBg"
              >
                          <span class="icon is-small mr-0"
                          ><i class="fas fa-rotate" aria-hidden="true"></i
                          ></span>
                <span class="editor-action-text">{{ this.$t("message.components.fabricComponent.kierraText") }}</span>
              </a>
            </div>
          </div>
        </div>

        <template v-slot:footer>
          <div class="column is-flex is-justify-content-end">
            <button class="pm-small-button" @click="saveCroppedImageBg">{{ this.$t("message.components.fabricComponent.saveText") }}</button>&nbsp;
            <button class="pm-small-button" @click="cancelCropModalBg">{{ this.$t("message.components.fabricComponent.cancelText") }}</button>
          </div>
        </template>
      </pop-modal>
    </div>
  </div>
  <EditorHelpModal ref="editorHelpModal"/>
</template>

<script>
import fabric from '@/components/_fabric'
import VueCroppieComponent from "@/components/VueCroppieComponent"
import Modal from "@/components/Modal"
import preview3d from "@/3d/preview3d"
import asModalDialog from "@/helpers/asModalDialog"
import cartMixin from "@/mixins/cartMixin"
import colorPickerMixin from "@/mixins/colorPickerMixin"
import EditorHelpModal from "@/modals/EditorHelpModal"
import {ColorPicker} from 'vue-accessible-color-picker'
import ColorPickerFooter from "@/components/ColorPickerFooter"
import {mapGetters} from "vuex";
import turtle from '@/assets/img/turtle.png'
const convert = require('color-convert');

export default {
  components: {
    "vue-croppie": VueCroppieComponent,
    "pop-modal": Modal,
    "preview3d": preview3d,
    "asModalDialog": asModalDialog,
    ColorPicker,
    EditorHelpModal,
    ColorPickerFooter,
  },
  mixins: [cartMixin, colorPickerMixin],
  data() {
    return {
      fonts: [
        "abrilfatface",
        "dancingscript",
        "domine",
        "josefinsans",
        "oswald",
        "robotoslab",
        "quicksand",
        "ubuntu",
        "specialelite"
      ],
      selectedFont: "abrilfatface",
      canvasInitColor: 'white',
      canvas: null,
      canRef: null,
      rect: null,
      img: '',
      textBox: null,
      circle: null,
      rectangle: null,
      colors: null,
      showLogo: false,
      croppieImageLogo: '',
      croppedLogo: null,
      isRedoing: false,
      redoUndoHistory: [],
      dataUrl: '',
      showBackground: false,
      croppedBg: '',
      croppedBgMaxZoom: 2.5,
      croppedBgMinZoom: 0.1,
      croppieDataBg: {},
      scale: 1,
      canvasHeight: 0,
      canvasWidth: 0,
      scaleFactor: 0.83,
      showBgColorPicker: false,
      showFontColorPicker: false,
      showShapesColorPicker: false,
      canvasGridVisible: false,
      backgroundColorCMYK: {
        cyan: 0,
        magenta: 0,
        yellow: 0,
        black: 0
      },
      fontColorCMYK: {
        cyan: 0,
        magenta: 0,
        yellow: 0,
        black: 100
      },
      shapeColorCMYK: {
        cyan: 0,
        magenta: 0,
        yellow: 0,
        black: 100
      }
    }
  },
  props: ['product'],
  mounted() {
    let productConf = this.product['convert_conf']
    this.canvasWidth = productConf['width']
    this.canvasHeight = productConf['height']
    this.canRef = this.$refs.can
    this.canvas = new fabric.Canvas(this.canRef)
    this.canvas.setDimensions({width: this.canvasWidth, height: this.canvasHeight})
    if (this.selectedProduct['fabric_metadata'] )
      this.canvas._loadHistory(this.selectedProduct['fabric_metadata'])
    this.canvas.stateful = true
    this.canvas.renderAll()
    this.canvas._historySaveAction()
    this.canvas.on('object:moving', this.moveHandler)   // Calls many times, find better solution if UI gets slow
    this.canvas.on('object:rotating', this.rotating)
    this.canvas.on('object:scaling', this.scaling)
    this.canvas.on('object:modified', this.getDataUrl)
    this.canvas.on('mouse:down', this.switchTab)

    this.changeBackgroundColor() // init white background color

    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      this.$nextTick(() => this.onResize())
      this.addTurtleLogo()
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapGetters([
      'getImagesBank'
    ]),
    onResize() {
      if (this.$refs.parentEditorWrapper) {
        let screenWidth = window.screen.width
        let width
        let height = this.$refs.parentEditorWrapper.clientHeight
        if (screenWidth < 1024) {
          width = screenWidth
          document.getElementById('parentEditorWrapper').style.maxWidth = `${width}px`
        }
        else {
          document.getElementById('parentEditorWrapper').style.maxWidth = document.getElementById('topEditorControls').style.width
          width = this.$refs.parentEditorWrapper.clientWidth
        }
        this.scale = (width * this.scaleFactor) / this.canvasWidth
        this.editorHeight = height / this.canvasHeight
        document.getElementById('editorcanvas').style.display = 'block'
      }
    },
    initialCanvas() {
      this.canvas.clear()
      this.croppedBg = ''
      this.canvas.backgroundColor = this.canvasInitColor
      this.canvas.renderAll()
      this.addTurtleLogo()
      this.canvas.setActiveObject(this.canvas.item(0))
    },
    undo() {
      this.canvas.undo()
    },
    addBackground() {
      this.$refs.backgroundImage.click()
    },
    bgColorIconClick() {
      if (this.croppedBg) return;
      this.showBgColorPicker = !this.showBgColorPicker
    },
    redo() {
      this.canvas.redo()
    },
    getDataUrl(e) {
      this.switchTab(e)
      this.dataUrl = this.canvas.toDataURL()
      this.selectedProduct['fabric_metadata'] = this.canvas._historyNext()
    },
    loadAndUse(font) {
      let FontFaceObserver = require('fontfaceobserver')
      let self = this
      let myfont = new FontFaceObserver(font)
      myfont.load()
          .then(function () {
            // when font is loaded, use it.
            self.canvas.getActiveObject().set("fontFamily", font)
            self.canvas.requestRenderAll()
          }).catch(function (e) {
      });
    },
    changeFont(font) {
      this.selectedFont = font
      this.$refs.fontModal.answerNAY()
      const activeObject = this.canvas.getActiveObject()
      if (activeObject && activeObject.type === 'i-text') {
        this.loadAndUse(this.selectedFont)
      }
    },
    switchTab(e) {
      const activeObject = this.canvas.getActiveObject()
      let type = ''
      if (activeObject) {
        if (activeObject instanceof fabric.Circle || activeObject instanceof fabric.Rect) {
          type = 'shape'
        } else {
          type = activeObject.type
        }
      }
      switch (type) {
        case 'i-text':
          this.openTab('tekstiContent', 'teksti-content')
          break
        case 'image':
          this.openTab('logoContent', 'logo-content')
          break
        case 'shape':
          this.openTab('shapesContent', 'shapes-content')
          break
        default:
          this.openTab('kuvaContent', 'kuva-content')
      }
    },
    changeFontColor() {
      const activeObject = this.canvas.getActiveObject()
      if (activeObject && activeObject.type === 'i-text') {
        activeObject.set("fill", this.fontColor)
        this.canvas.requestRenderAll()
      }
      this.showFontColorPicker = false
    },
    changeShapesColor() {
      const activeObject = this.canvas.getActiveObject()
      if (activeObject && (activeObject instanceof fabric.Circle || activeObject instanceof fabric.Rect)) {
        activeObject.set("stroke", this.shapeColor)
        this.canvas.requestRenderAll()
      }
      this.showShapesColorPicker = false
    },
    changeBackgroundColor() {
      this.canvas.backgroundColor = this.backgroundColor
      this.canvas.renderAll()
      this.canvas._historySaveAction()
      this.showBgColorPicker = false
    },
    updateBgColor (eventData) {
      let { h, s, l } = this.parseHSL(eventData.cssColor)
      let cmyk = this.hslToCmyk(h, s, l)
      this.backgroundColorCMYK.cyan = cmyk.cyan
      this.backgroundColorCMYK.black = cmyk.black
      this.backgroundColorCMYK.magenta = cmyk.magenta
      this.backgroundColorCMYK.yellow = cmyk.yellow
    },
    updateFontColor (eventData) {
      let { h, s, l } = this.parseHSL(eventData.cssColor)
      let cmyk = this.hslToCmyk(h, s, l)
      this.fontColorCMYK .cyan = cmyk.cyan
      this.fontColorCMYK.black = cmyk.black
      this.fontColorCMYK.magenta = cmyk.magenta
      this.fontColorCMYK.yellow = cmyk.yellow
    },
    updateShapeColor (eventData) {
      let { h, s, l } = this.parseHSL(eventData.cssColor)
      let cmyk = this.hslToCmyk(h, s, l)
      this.shapeColorCMYK.cyan = cmyk.cyan
      this.shapeColorCMYK.black = cmyk.black
      this.shapeColorCMYK.magenta = cmyk.magenta
      this.shapeColorCMYK.yellow = cmyk.yellow
    },
    showGridIfRequired() {
      if (this.canvasGridVisible)
        this.addGrid()
    },
    flipGrid(changeStatus=true) {
      if (!this.canvasGridVisible)
        this.addGrid()
      else
        this.removeGrid()
      if (changeStatus)
        this.canvasGridVisible = !this.canvasGridVisible
    },
    addGrid() {
      const cupsGrids = {
        1: 'cup-grid-6oz.png',
        2: 'cup-grid-8oz.png',
        3: 'cup-grid-12oz.png',
        4: 'cup-grid-20oz.png'
      }
      let self = this
      // Just to get scaling values
      fabric.Image.fromURL(`${process.env.BASE_URL}3d/${cupsGrids[self.product.id]}`, (img) => {
        this.canvas.setOverlayImage(
         `${process.env.BASE_URL}3d/${cupsGrids[self.product.id]}`,
          this.canvas.renderAll.bind(this.canvas),
          {
            scaleX: self.canvas.getWidth() / img.width,
            scaleY: self.canvas.getHeight() / img.height,
            objectCaching: false,
            originX: 'left',
            originY: 'top',
            opacity: 1,
          }
        )
      })
    },
    removeGrid() {
      this.canvas.setOverlayImage(null, this.canvas.renderAll.bind(this.canvas))
    },
    addTextBox() {
      let FontFaceObserver = require('fontfaceobserver')
      let self = this
      let myfont = new FontFaceObserver(this.selectedFont)
      myfont.load()
        .then(function () {
          // when font is loaded, use it.
          self.textBox = new fabric.IText('Text', {
            width: 400,
            textAlign: 'center',
            originX: 'center',
            originY: 'center',
            fontSize: '60',
            fill: self.fontColor,
            fontFamily: self.selectedFont,
            top: self.canvasHeight / 2,
            left: self.canvasWidth / 2,
            borderColor: "#23B6BB",
            borderScaleFactor: 3,
            borderOpacityWhenMoving: 1,
            cornerColor: "#D9C731",
            cornerStrokeColor: "#D9C731",
            transparentCorners: false,
            cornerStyle: 'circle',
            cornerSize: 20,
          })
          self.canvas.add(self.textBox)
          let turtle = self.canvas.item(0)
          turtle.bringToFront()
        }).catch(function (e) {
        console.log(e)
      });
    },
    addLogo() {
      this.$refs.imageUpload.click()
    },
    deleteItem() {
      this.canvas.getActiveObjects().forEach((obj) => {
        if (obj.id != 'turtle') {
          console.log(obj.id)
          this.canvas.remove(obj)
        }
      })
      this.canvas.discardActiveObject().renderAll()
    },
    cancelCropModal() {
      this.croppedLogo = null
      this.showLogo = false
      this.croppedLogo = ''
    },
    cancelCropModalBg() {
      this.showBackground = false
    },
    saveCroppedImageLogo() {
      new fabric.Image.fromURL(this.croppieImageLogo, (img) => {
        // add background image
        this.img = img
        this.canvas.add(this.img)
        this.canvas.centerObject(this.img)
        this.canvas.renderAll()
        let turtle = this.canvas.item(0)
        turtle.bringToFront()
      },
      {
        borderColor:"#23B6BB",
        borderScaleFactor: 3,
        borderOpacityWhenMoving: 1,
        cornerColor: "#D9C731",
        cornerStrokeColor: "#D9C731",
        transparentCorners: false,
        cornerStyle:'circle',
        cornerSize: 20,
      })
      this.cancelCropModal()
    },
    saveCroppedImageBg(cancelCropModal=true) {
      new fabric.Image.fromURL(this.croppieImageBg, (img) => {
        this.canvas.setBackgroundImage(img, this.canvas.renderAll.bind(this.canvas), {
          scaleX: this.canvas.width / img.width,
          scaleY: this.canvas.height / img.height
        })
        this.canvas.renderAll()
        this.canvas._historySaveAction()
        if (cancelCropModal) {
            this.cancelCropModalBg()
        }
      })
    },
    addTurtleLogo() {
      // check first if there is not a turtle logo already present
      let turtleExist = false
      let objects = this.canvas.getObjects()

      objects.forEach(function(item) {
        if (item.id == 'turtle') {
          turtleExist = true
        }
      });

      if (!turtleExist) {
        this.croppieImageLogo = turtle
        new fabric.Image.fromURL(this.croppieImageLogo, (img) => {
              this.img = img
              this.canvas.add(this.img)
              this.img.bringToFront()
              this.img.bringForward()
            },
            {
              id: 'turtle',
              hasControls: true,
              hasBorders: true,
              selection: true,
              lockMovementX: false,
              lockMovementY: false,
              left: this.canvas.width - 400,
              top: this.canvas.height - 203,
              lockScalingX: true,
              lockScalingY: true,
              selectable: true,
              absolutePositioned: true
            })
        this.croppieImageLogo = ''
      }
    },
    croppieLogo(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      let reader = new FileReader()
      reader.onload = e => {
        this.$refs.croppieRefLogo.bind({
          url: e.target.result
        })
      }
      reader.readAsDataURL(files[0])
      this.showLogo = true
      e.target.value = null
    },
    croppieBg(e) {
      this.$refs.imageModal.answerNAY()
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      let reader = new FileReader()
      reader.onload = e => {
        this.$refs.croppieRefBg.bind({
          url: e.target.result
        })
      }
      reader.readAsDataURL(files[0])
      this.showBackground = true
      e.target.value = null
    },
    selectImage(image) {
      this.$refs.imageModal.answerNAY()
      this.$refs.croppieRefBg.bind({
        url: image.image
      })
      this.showBackground = true
    },
    changeCropperLogo() {
      let options = {
        type: 'base64',
        size: "original",
        quality: 1,
        format: 'png'
      };
      this.$refs.croppieRefLogo.result(options, output => {
        this.croppedLogo = this.croppieImageLogo = output
      })
    },
    changeCropperBg() {
      let options = {
        type: 'base64',
        size: "original",
        quality: 1,
        format: 'png'
      }
      this.$refs.croppieRefBg.result(options, output => {
        this.croppedBg = this.croppieImageBg = output
        this.croppieDataBg = this.$refs.croppieRefBg.get()
      })
    },
    rotateLogo() {
      this.$refs.croppieRefLogo.rotate(90)
    },
    rotateBg() {
      this.$refs.croppieRefBg.rotate(90)
      this.changeCropperBg()
    },
    checkBoundingBox(e) {
      const obj = e.target

      if (!obj) {
        return
      }
      obj.setCoords()

      const objBoundingBox = obj.getBoundingRect()
      if (objBoundingBox.top < 10) {
        obj.set('top', 10)
        obj.setCoords()
      }
      if (objBoundingBox.left > this.canvas.width - objBoundingBox.width - 50) {
        obj.set('left', this.canvas.width - objBoundingBox.width - 50)
        obj.setCoords()
      }
      if (objBoundingBox.top > this.canvas.height - objBoundingBox.height - 10) {
        obj.set('top', this.canvas.height - objBoundingBox.height - 10)
        obj.setCoords()
      }
      if (objBoundingBox.left < 10) {
        obj.set('left', 10)
        obj.setCoords()
      }
    },
    moveHandler(e) {
      this.checkBoundingBox(e)
    },
    rotating(e) {
      this.checkBoundingBox(e)
    },
    scaling(e) {
      this.checkBoundingBox(e)
    },
    openTab(elemId, tabName) {
      let i, x, tablinks
      x = document.getElementsByClassName("content-tab")
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none"
      }
      tablinks = document.getElementsByClassName("tab")
      for (i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(" is-active", "")
      }
      document.getElementById(tabName).style.display = "flex"
      document.getElementById(elemId).className += " is-active"
    },
    open3DPreview() {
      this.canvas.discardActiveObject() // deselect any selected item
      this.removeGrid()
      this.canvas.renderAll()
      let canvas = document.getElementById('editorcanvas')
      this.dataUrl = canvas.toDataURL()
      this.$refs.previewModal.showModal()
    },
    openFontModal() {
      this.$refs.fontModal.showModal()
    },
    openBankImageModal() {
      this.$refs.imageModal.showModal()
    },
    openHelpModal() {
      this.$refs.editorHelpModal.showModal();
    },
    deleteBackgroundPicture() {
      this.canvas.setBackgroundImage(null, this.canvas.renderAll.bind(this.canvas))
      this.croppedBg = ''
    },
    addCircle() {
      this.circle = new fabric.Circle({
        radius: 50,
        fill: 'transparent',
        left: 100,
        top: 100,
        borderColor: "#23B6BB",
        borderScaleFactor: 3,
        borderOpacityWhenMoving: 1,
        cornerColor: "#D9C731",
        cornerStrokeColor: "#D9C731",
        transparentCorners: false,
        cornerStyle: 'circle',
        cornerSize: 20,
        hasBorders: true,
        hasControls: true,
        stroke: this.shapeColor,
        strokeWidth: 7
      })

      this.canvas.add(this.circle)
      let turtle = this.canvas.item(0)
      turtle.bringToFront()
    },
    addRectangle() {
      this.rectangle = new fabric.Rect({
        width: 100,
        height: 100,
        left: 100,
        top: 100,
        stroke: this.shapeColor,
        strokeWidth: 7,
        fill: 'transparent',
        cornerColor: "#D9C731",
        cornerStrokeColor: "#D9C731",
        transparentCorners: false,
        cornerStyle: 'circle',
        cornerSize: 20,
      })
      this.canvas.add(this.rectangle)
      let turtle = this.canvas.item(0)
      turtle.bringToFront()
    }
  },
  computed: {
    bgCroppieBoundary() {
      return {
        width: this.canvasWidth / 4.2, height: this.canvasHeight / 4.2
      }
    },
    bgCroppieViewport() {
      return {
        width: this.canvasWidth / 4.2, height: this.canvasHeight / 4.2, 'type':'square'
      }
    },
    editorWrapperStyle() {
      return {
        'transform': `scale(${this.scale})`
      }
    },
    editorHeightProperty() {
      return {
        'height': `${10 + (this.canvasHeight * this.scale)}px !important`
      }
    },
    texture() {
      return [{
        dataUrl: this.dataUrl,
        posx: 0,
        posy: 0,
        width: 1000,
        height: 400,
        rotate: 0
      }]
    },
    textureUri() {
      let path = ''
      if(this.product.name.includes('(6 oz)')) {
        path = `${process.env.BASE_URL}3d/paper-cup-6oz-v5.glb`
      }
      if(this.product.name.includes('(8 oz)')){
        path = `${process.env.BASE_URL}3d/paper-cup-8oz-v5.glb`
      } 

      if(this.product.name.includes('(12 oz)')){
        path =  `${process.env.BASE_URL}3d/paper-cup-12oz-v4.glb`
      } 
      if(this.product.name.includes('(20 oz)')) {
        path =  `${process.env.BASE_URL}3d/paper-cup-20oz-v5.glb`
      }
      return path;
    },
    hasBackgroundPicture() {
      if (this.canvas != null && this.canvas.backgroundImage != null) {
        return true
      }
      return false
    },
    shapeColor() {
      let rgb = convert.cmyk.rgb([
          parseInt(this.shapeColorCMYK.cyan),
          parseInt(this.shapeColorCMYK.magenta),
          parseInt(this.shapeColorCMYK.yellow),
          parseInt(this.shapeColorCMYK.black)
        ]
      )
      const hex = convert.rgb.hex(rgb);
      return '#' + hex
    },
    fontColor() {
      let rgb = convert.cmyk.rgb([
          parseInt(this.fontColorCMYK.cyan),
          parseInt(this.fontColorCMYK.magenta),
          parseInt(this.fontColorCMYK.yellow),
          parseInt(this.fontColorCMYK.black)
        ]
      )
      const hex = convert.rgb.hex(rgb);
      return '#' + hex
    },
    backgroundColor() {
      let rgb = convert.cmyk.rgb([
          parseInt(this.backgroundColorCMYK.cyan),
          parseInt(this.backgroundColorCMYK.magenta),
          parseInt(this.backgroundColorCMYK.yellow),
          parseInt(this.backgroundColorCMYK.black)
        ]
      )
      const hex = convert.rgb.hex(rgb);
      return '#' + hex
    }
  }
}
</script>
<style lang="scss" scoped>

@import url('vue-accessible-color-picker/styles');
@import "../assets/sass/variables";

canvas {
  border-bottom: 0px solid lightgray;
  border-left: 0px solid lightgray;
  border-right: 0px solid lightgray;
  width: 100%;
  margin-top: 0;
  cursor: default;
}

.editor-wrapper {
  transform-origin: top left;
}

h2 {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}

.button {
  display: flex;
  justify-content: center;
  background-color: #333;
  border: 1px solid grey;
  padding: 2px 10px;
  text-transform: uppercase;
  color: white;
  width: 37px;
  height: 37px;
  margin-top: 0px;
  cursor: pointer;
}

.button:hover,
.button:focus {
  border-color: white;
  outline: none;
}

input[type="file"] {
  display: none;
}

body {
  font-family: Arial, Helvetica, sans-serif;
}

.d-color {
  color: #D9C731 !important;
}

.tab:first-child {
  border-left: 0px;
  .editor-text-icon {
    border-left: 0px;
  }
}

.tab:last-child {
  border-right: 0px;
  .editor-text-icon {
    border-right: 0px;
  }
}

.tab {
  background-color: #F0EFE477;
  border-bottom: 1px solid black;
  border-right: 1px solid black;
}

.tab.is-active {
  border-bottom: 0px;
  .editor-text-icon {
    svg {
      color: $pm-blue;
    }
    .editor-action-text {
      color: $pm-blue;
    }
  }
}

.tab .editor-text-icon {
  svg {
    color: $font-color-primary;
    font-size: 35px;
    margin-top: 15px;
  }
  .editor-action-text {
    color: $font-color-primary;
    font-size: 15px;
    font-family: "Open Sans";
    margin-top: 20px;
  }
}

.editor-text-icon {
  svg {
    color: $pm-blue;
    font-size: 35px;
    margin-top: 15px;
  }
  .editor-action-text {
    color: $pm-blue;
    font-size: 15px;
    font-family: "Open Sans";
    margin-top: 20px;
  }
}

.editor-text-icon-modal {
  svg {
    color: $pm-blue;
    font-size: 35px;
    margin-top: 15px;
  }
  .editor-action-text {
    color: $pm-blue;
    font-size: 15px;
    font-family: "Open Sans";
    margin-left: 30px;
    position: relative;
    top: -10px;
  }
}

.default-active {
  display: flex;
}

.border-bottom {
  border-bottom: 1px solid #707070;
}

.top-editor-controls {
  svg {
    color: $font-color-primary;
    font-size: 25px;
  }
  .top-editor-text {
    font-size: 16px;
    font-family: "Open Sans";
    margin-top: 7px;
    color: $font-color-primary;
  }
}

.editor-wrapper canvas {
  border: 2px solid #707070;
}

.is-bordered {
  border: 1px solid #161637;
}

// font dropdown

.fontDropdown {
  position: relative;
  display: inline-block;
}

.font-dropdown-content {
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.font-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.fontDropdown a:hover {background-color: #ddd;}

.bg-color-picker-wrapper {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid gray;
  background-color: white;
  z-index: 100;
}

.icon-disabled {
  cursor: not-allowed !important;
  svg {
    color: #dddddd;
  }
}

.text-disabled {
  color: #dddddd !important;
}

.fontSelector {
  text-align: center;
  a {
    color: black;
    width: 150px;
    display: inline-block;
  }
  a:hover {
    border: 1px solid $pm-blue;
  }
  a.selected {
    border: 3px solid $pm-blue;
  }
}

p {
  text-align: center;
  font-family: "mokokoregular";
  font-size: 20px;
}

.imageSelector {
  overflow-y: scroll;
  max-height: 300px;

  img:hover {
    cursor: pointer;
    border: 1px solid $pm-blue;
  }
}

#color-preview {
  width: 100px;
  height: 100px;
  border: 1px solid #000;
}
</style>
