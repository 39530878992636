import { createStore } from "vuex"
import VuexPersistence from "vuex-persist"
import doAsync from "./async-util"
import * as types from "./mutation-types"
import localforage from "localforage"

const local_store = localforage.createInstance({
  name: "pmstore",
});

const vuexPersist = new VuexPersistence({
  storage: local_store,
  reducer: (state) => {
    return JSON.parse(JSON.stringify(state));
  },
  asyncStorage: true,
});

let state_dict = {
  token: '',
  order: {},
  products: [], // service configured products
  discount_volumes: [],
  cartSpinnerActive: false,
  spinnerActive: false,
  notificationActive: false,
  notificationMessage: '',
  reseting: false,
  postContactData: null,
  putContactData: null,
  temporal_contact_data: null,
  image_bank: [],
  cookieNotificationShow: true,
  resetOrderNotificationShow: false,
  googleCookieEnabled: false,
  serviceFees: [],
  fixedVoucher: {},
  i18n: 'fi'
}

let store_config = {
  state() {
    return state_dict
  },
  mutations: {
    setAuth(state, token) {
      state.token = token
    },      
    partialReset(state) {
      let token = state.token
      Object.assign(state, state_dict)
      state.token  = token
    },
    setSpinnerState(state, spinnerState) {
      state.spinnerActive = spinnerState
    },
    setCartSpinnerState(state, spinnerState) {
      state.cartSpinnerActive = spinnerState
    },
    setProducts(state, data) {
      state.products = data
    },
    setServiceFees(state, data) {
      state.serviceFees = data
    },
    setOrder(state, data) {
      state.order = data
    },
    seti18n(state, value) {
      state.i18n = value
    },
    setVolumesDiscount(state, data) {
      state.discount_volumes = data
    },
    setNotificationActiveState(state, data) {
      state.notificationActive = data['notificationState']
      state.notificationMessage = data['message']
    },
    setReseting(state, reset) {
      state.reseting = reset
    },
    setFixedVoucher(state, data) {
      state.fixedVoucher = data
    },
    setImagesBank(state, data) {
      state.image_bank = data
    },
    setCookieNotificationShow(state, value) {
      state.cookieNotificationShow = value
    },
    setGoogleCookieEnabled(state, value) {
      state.googleCookieEnabled = value
    },
    setResetOrderNotificationShow(state, value) {
      state.resetOrderNotificationShow = value
    },
    resetStore(state) {
      state_dict.cookieNotificationShow = state.cookieNotificationShow // keep cookie value
      state_dict.googleCookieEnabled = state.googleCookieEnabled // keep google cookie value      
      Object.assign(state, state_dict)
    },
  },
  getters: {
    getToken(state) {
      return state.token
    },
    getOrderData(state) {
      return state.order
    },
    getVolumesDiscount: (state) => (product) => {
      return state.discount_volumes.filter(prod => {
         return prod.product_id == product
      })
    },
    getCartSpinnerState(state) {
      return state.cartSpinnerActive
    },
    getProducts(state) {
      return state.products
    },
    getCups(state) {
      return state.products.filter(el => el.resource_type == 'Cup')
    },
    getLids(state) {
      return state.products.filter(el => el.resource_type == 'Lid')
    },
    getProductDescription: (state) => (productId) => {
      if(state.i18n) return state.products.find(el => el.id == productId).description[`${state.i18n}`]
      return state.products.find(el => el.id == productId).description['fi']
    },
    getDesignServiceFee(state) {
      const DESIGN_SERVICE_ID = 1
      return state.serviceFees.find((el) => el.id == DESIGN_SERVICE_ID)
    },
    getImagesBank(state) {
      return state.image_bank
    },
    getProductById: (state) => (id) => {
      return state.products.filter(prod => {
         return prod.id == id
      })
    },
    getFixedVoucher(state) {
      return state.fixedVoucher
    },
    getNotificationActive(state) {
      return state.notificationActive
    },
    getResetOrderNotificationShow(state) {
      return state.resetOrderNotificationShow
    },
    getNotificationMessage(state) {
      return state.notificationMessage
    },
    getItemsTotalAmount: (state) => {
      let total = 0
      if (state.order == null || state.order == {}) {
        return 0
      }
      let items = state.order.items.filter(el => el.draft === false)
      items.forEach(function(item) {
        total += item.amount
      })
      let forms = state.order.orderforms.filter(el => el.draft === false)
      forms.forEach(function(item) {
        total += item.amount
      })         
      return total
    },
    getItemsTotalAmountByProduct: (state) => (productId) => {
      let total = 0
      if (state.order == null || state.order == {} || state.order.items == undefined) {
        return 0
      }
      let items = state.order.items.filter(el => el.draft === false && el.product.id == productId)
      items.forEach(function(item) {
        total += item.amount
      })
      let forms = state.order.orderforms.filter(el => el.draft === false && el.product.id == productId)
      forms.forEach(function(item) {
        total += item.amount
      })      
      return total
    },
    getContactData: (state) => {
      if (state.postContactData != null) {
        return state.postContactData
      } else {
        return state.temporal_contact_data
      }
    },   
    getReseting(state) {
      return state.reseting
    },
    getCookieShowNotification(state) {
      return state.cookieNotificationShow
    },
    getGoogleCookieEnabled(state) {
      return state.googleCookieEnabled
    },
    getSavedItems: (state) => {
      if (state.order == null || state.order.items == undefined) {
        return []
      }
      return state.order.items.filter(el => el.draft === false)
    },
    getNonDraftOrderForms: (state) => {
      if (state.order == null || state.order.orderforms == undefined) {
        return []
      }
      return state.order.orderforms.filter(el => el.draft === false)      
    }
  },
  actions: {
    getAuthenticate(store, force=false) {
      if (!force || store.state.token != '') {
        return
      }
      return doAsync(store, {
        url: `login/`,
        mutationTypes: types.GET_AUTHENTICATE,
        method: 'post',
      }).then((res) => {
        if(res.status === 200) {
          store.commit('setAuth', res.data.token)
        }
        return res
      })
    },
    getProducts(store) {
      return doAsync(store, {
        url: `products/`,
        mutationTypes: types.GET_PRODUCTS,
        method: 'get',
      }).then(() => {
        store.commit('setProducts', store.state.getProductsData)
      })
    },
    postOrder(store) {
      return doAsync(store, {
        url: `order/`,
        mutationTypes: types.POST_ORDER,
        method: 'post',
      }).then(() => {
        store.commit('setOrder', store.state.postOrderData)
      })
    },
    partialUpdateOrder(store, orderData) {
      orderData['order_number'] = store.state.order.order_number
      return doAsync(store, {
        url: 'order/patch/',
        mutationTypes: types.PARTIAL_UPDATE_ORDER,
        method: 'patch',
        data: orderData,
      }).then(() => {
        store.commit('setOrder', store.state.partialUpdateOrderData)
      })
    },      
    getOrder(store) {
      let order_number = store.state.order.order_number
      return doAsync(store, {
        url: `order/${order_number}/`,
        mutationTypes: types.GET_ORDER,
        method: 'get',
      }).then((res) => {
        if (res.status == 404) {
          store.commit('resetStore')
        } else {
          store.commit('setOrder', store.state.getOrderData)
        }
      })
    },
    postItem(store, data) {
      return doAsync(store, {
        url: `item/`,
        mutationTypes: types.POST_ITEM,
        method: 'post',
        data: data,
      })
    },
    updateItem(store, data) {
      return doAsync(store, {
        url: `item/${data['id']}/`,
        mutationTypes: types.UPDATE_ITEM,
        method: 'patch',
        data: data,
      })
    },    
    listDiscountVolumes(store) {
      return doAsync(store, {
        url: `volume-discount/`,
        mutationTypes: types.LIST_DISCOUNT_VOLUMES,
        method: 'get',
      }).then(() => {
        store.commit('setVolumesDiscount', store.state.listDiscountVolumesData)
      })
    },
    deleteItem(store, item_id) {
      return doAsync(store, {
        url: `item/${item_id}/`,
        mutationTypes: types.DELETE_ITEM,
        method: 'delete',
      })
    },
    postRequestOrderForm(store, data) {
      return doAsync(store, {
        url: `requestorderform/`,
        mutationTypes: types.POST_REQUEST_ORDER_FORM,
        method: 'post',
        data: data,
      })
    },
    updateRequestOrderForm(store, data) {
      // True formData
      return doAsync(store, {
        url: `requestorderform/${data['id']}/`,
        mutationTypes: types.UPDATE_REQUEST_ORDER_FORM,
        method: 'patch',
        data: data
      })
    },    
    deleteRequestOrderForm(store, item_id) {
      return doAsync(store, {
        url: `requestorderform/${item_id}/`,
        mutationTypes: types.DELETE_REQUEST_ORDER_FORM,
        method: 'delete',
      })
    },    
    postContact(store, formData) {
      formData['order'] = store.state.order.order_number
      return doAsync(store, {
        url: 'contact/',
        mutationTypes: types.POST_CONTACT,
        method: 'post',
        data: formData,
      })
    },
    updateContact(store, formData) {
      formData['order'] = store.state.order.order_number
      return doAsync(store, {
        url: `contact/${store.state.postContactData.id}/`,
        mutationTypes: types.PUT_CONTACT,
        method: 'put',
        data: formData,
      })
    },  
    createStripeSession(store) {
      return doAsync(store, {
        url: `order/${store.state.order.order_number}/create_stripe_session/`,
        mutationTypes: types.STRIPE_SESSION,
        method: 'post',
      })
    },
    listImageBank(store) {
      return doAsync(store, {
        url: `image-bank/`,
        mutationTypes: types.IMAGE_BANK,
        method: 'get',
      }).then(() => {
        store.commit('setImagesBank', store.state.imageBankData)
      })
    },
    addVoucher(store, data) {
      return doAsync(store, {
        url: `voucher/${data['code']}/`,
        mutationTypes: types.ADD_VOUCHER,
      })
    },
    postNewsletter(store, data) {
      return doAsync(store, {
        url: 'newsletter/',
        mutationTypes: types.POST_NEWSLETTER,
        method: 'post',
        data: data,
      })
    },
    getServiceFees(store) {
      return doAsync(store, {
        url: `service-fee/`,
        mutationTypes: types.GET_SERVICE_FEES,
        method: 'get',
      }).then(() => {
        store.commit('setServiceFees', store.state.getServiceFeesData)
      })
    },    
  },
  plugins: [vuexPersist.plugin],
}

/*
 * For each async mutation, dynamically add the three mutations:
 * SUCCESS - write the response to the store using the `stateKey` property
 * PENDING - set the `loadingKey` property in the store to true
 * FAILURE - Set `loadingKey` to false, and `errorCode` - for example 401, 404...
 *
 * stateKey, errorCode and loadingKey are prepended with the name of the action,
 * for example an action getData would lead to:
 *
 * mutations:
 *   GET_DATA_SUCCESS,
 *   GET_DATA_PENDING,
 *   GET_DATA_FAILURE
 *
 * state:
 *   getDataLoadingKey,
 *   getDataStateKey,
 *   getDataErrorCode
 *
 * For all mutations following defaults are applied:
 *   getDataLoadingKey = false
 *   getDataStateKey = null
 *   getDataStatusCode = null
 *   getDataErrorCode = null
 */
Object.keys(types).forEach((type) => {
  // add the initial state
  state_dict[types[type].loadingKey] = false
  state_dict[types[type].stateKey] = null
  state_dict[types[type].statusCode] = null
  state_dict[types[type].FAILURE] = null

  // add the mutation
  store_config['mutations'][types[type].BASE] = (state, payload) => {
    switch (payload.type) {
      case types[type].PENDING:
        state[types[type].loadingKey] = payload.value
        return state[types[type].loadingKey]

      case types[type].SUCCESS:
        state[types[type].statusCode] = payload.statusCode
        state[types[type].FAILURE] = null
        state[types[type].stateKey] = payload.data
        return state[types[type].stateKey]

      case types[type].FAILURE:
        state[types[type].statusCode] = payload.statusCode
        state[types[type].FAILURE] = payload.error
        return state[types[type].FAILURE]
    }
  }
})

const store = createStore(store_config)

export default store


